<script lang="ts" setup>
import { useTheme } from "vuetify";
import { specializationList } from "~/state/state";

defineProps({ modelValue: Boolean });
defineEmits(["update:modelValue"]);

const { themes } = useTheme();
const { medica } = themes.value.customTheme.colors;
</script>

<template>
    <VRow no-gutters class="flex-column flex-nowrap fill-height pr-3">
        <VCol cols="auto py-4">
            <VBtn
                icon="$close"
                size="small"
                color="white"
                variant="flat"
                aria-label="`Close menu.`"
                title="Close"
                style="position: fixed; top: 0; right: 0; z-index: 100"
                @click="$emit('update:modelValue', !modelValue)"
            ></VBtn>
        </VCol>
        <VCol cols="auto">
            <VList color="purple-dark" class="navigation-wrapper" nav density="compact">
                <VListItem title="Home" to="/"></VListItem>
                <VListGroup value="services" bg-color="red">
                    <template #activator="{ props }">
                        <VListItem v-bind="props" title="Services"></VListItem>
                    </template>

                    <VListGroup v-for="specialization in specializationList.getElements()" :key="specialization.id" :value="specialization.name">
                        <template #activator="{ props }">
                            <VListItem v-bind="props" :title="specialization.name"></VListItem>
                        </template>
                        <VListItem title="Explore" :to="`/specialization/${stringToHash(specialization.name)}/${specialization.id}`"></VListItem>
                        <VListItem
                            v-for="service in specialization.servicesNamesAndPrices"
                            :key="service.id"
                            :title="service.name"
                            :to="`/service/${stringToHash(specialization.name)}/${stringToHash(service.name)}/${service.id}`"
                        ></VListItem>
                    </VListGroup>
                </VListGroup>

                <VListItem title="Team" to="/staff"></VListItem>
                <VListItem title="Cost" to="/cost"></VListItem>
                <VListItem title="FAQ" to="/faq"></VListItem>
                <VListItem title="Gallery" to="/gallery"></VListItem>
                <VListItem title="Contact us" to="/contact"></VListItem>
            </VList>
        </VCol>
        <VCol class="d-flex align-center justify-center">
            <NuxtLink to="/">
                <Svg name="allmedica-logo-horizontal" role="link" width="auto" height="2.5rem" :color="medica"></Svg>
            </NuxtLink>
        </VCol>
    </VRow>
</template>
